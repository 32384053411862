@font-face {
  font-family: "Invention";
  src: url(assets/fonts/MSD_CORE_HEQ_InventionRgFonts.woff);
}

$primary-green: #00857c;
$primary-green-active: #00857cf5;
$primary-blue: dodgerblue;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Invention";
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #00857c;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 1px;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom-color: white !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: white !important;
}
.custom-dialog {
  background-color: rgb(24 24 24) !important;
}

:root {
  --mat-form-field-container-vertical-padding: 6px;
  /* General Font Settings for MDC Components */
  --mdc-typography-font-family: "invention";

  /* Text Fields */
  --mdc-filled-text-field-label-text-font: "invention";
  --mdc-outlined-text-field-label-text-font: "invention";
  --mdc-text-field-helper-text-font: "invention";

  /* Buttons */
  --mdc-button-label-text-font: "invention";
  --mdc-text-button-label-text-font: "invention";
  --mdc-contained-button-label-text-font: "invention";
  --mdc-outlined-button-label-text-font: "invention";

  /* Dialogs */
  --mdc-dialog-title-text-font: "invention";
  --mdc-dialog-content-text-font: "invention";
  --mdc-dialog-action-text-font: "invention";

  /* Selects */
  --mdc-select-label-text-font: "invention";
  --mdc-select-value-text-font: "invention";

  /* Chips */
  --mdc-chip-label-text-font: "invention";

  /* Sliders */
  --mdc-slider-label-text-font: "invention";

  /* Checkboxes */
  --mdc-checkbox-label-text-font: "invention";

  /* Radios */
  --mdc-radio-label-text-font: "invention";

  /* Menus */
  --mdc-menu-item-label-text-font: "invention";

  /* Lists */
  --mdc-list-item-label-text-font: "invention";

  /* Tables */
  --mdc-data-table-header-label-text-font: "invention";
  --mdc-data-table-body-label-text-font: "invention";
  --mdc-data-table-footer-label-text-font: "invention";

  /* Snackbars */
  --mdc-snackbar-label-text-font: "invention";
  --mdc-snackbar-action-label-text-font: "invention";

  /* Toolbars */
  --mdc-toolbar-title-text-font: "invention";

  /* Form Fields */
  --mdc-form-field-label-text-font: "invention";

  /* Paginator */
  --mdc-paginator-label-text-font: "invention";

  /* Tabs */
  --mdc-tab-label-text-font: "invention";

  /* Expansion Panels */
  --mdc-expansion-panel-header-text-font: "invention";
  --mdc-expansion-panel-body-text-font: "invention";

  /* Tooltips */
  --mdc-tooltip-label-text-font: "invention";

  /* Cards */
  --mdc-card-header-title-text-font: "invention";
  --mdc-card-subtitle-text-font: "invention";
  --mdc-card-content-text-font: "invention";

  /* Progress Bar and Spinner */
  --mdc-progress-indicator-label-text-font: "invention";

  --mat-option-label-text-font: "invention";

  --mat-form-field-container-vertical-padding: 16px;
  --mdc-text-button-label-text-weight: 700;
}
